<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('payment_intro')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('payment_description')">
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="isLoadingPayments || isLoadingSubs" style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <ng-container *ngIf="!isLoadingPayments && !isLoadingSubs && errorsArr.length > 0">
      <app-general-errors [errors]="errorsArr"></app-general-errors>
    </ng-container>
    <ng-container *ngIf="!isLoadingPayments && !isLoadingSubs && errorsArr.length == 0">
      <mat-card appearance="outlined" class="mb-3">
        <mat-card-title class="m-3" [innerHTML]="translationService.getTranslation('payment_bankinfos')">
        </mat-card-title>
        <mat-card-content>
            <div class="row mb-3" *ngFor="let payment of payments">
              <div class="col-8">
                    <div [innerHTML]="translationService.getTranslation('payment_iban') + ': ' + payment.bank.swiftIBAN | bankAccount ">
                    </div>
              </div>
              <div class="col-4 editButtonContainer text-center">
                <button *ngIf="payment.webId <= 0" mat-raised-button color="primary" (click)="paymentEdit(payment)" [innerHTML]="translationService.getTranslation('button_change')"></button>
                <fa-icon [icon]="'hourglass'" size="2x" *ngIf="payment.webId > 0" placement="top" [ngbTooltip]="translationService.getTranslation('payment_existing_change')"></fa-icon>
              </div>
            </div>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-title class="m-3" [innerHTML]="translationService.getTranslation('payment_editsub')">
        </mat-card-title>

        <mat-card-content>
            <div class="row mb-3" *ngFor="let sub of subs">
              <div class="col-8">
                <div>
                  {{sub.productDescription}} / {{sub.variantDescription}}
                    <div [innerHTML]="translationService.getTranslation('order_id') + ': ' + sub.backendId">
                    </div>
                </div>
              </div>
              <div class="col-4 editButtonContainer text-center" >
                <button mat-raised-button color="primary" (click)="subPaymentEdit(sub)" [innerHTML]="translationService.getTranslation('button_change')" *ngIf="sub.curPayment.webId <= 0"></button>
                <fa-icon [icon]="'hourglass'" size="2x" *ngIf="sub.curPayment.webId > 0" placement="top" [ngbTooltip]="translationService.getTranslation('payment_existing_change')"></fa-icon>
              </div>
            </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>
